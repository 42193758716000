import * as Sentry from '@sentry/react';
import axios from 'axios';

import { ExpandedUser } from 'src/common/types/user/ExpandedUser';
import { getAuthTokens } from 'src/controller/Keycloak/keycloak';

export const verifyUser = async (): Promise<ExpandedUser> => {
  const authTokens = getAuthTokens() ?? undefined;

  const authorization = `${authTokens?.token_type} ${authTokens?.access_token}`;
  const headers = { authorization };

  try {
    if (!authTokens) throw new Error('Token not found');
    const { data } = await axios.post('/api/v2/internal/user/verify', {}, { headers });

    return data.user;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }
    Sentry.captureException(error, { extra: { url: '/api/v2/internal/verifyUser' } });
    throw new Error(error as any);
  }
};
