import axios from 'axios';
import { useAuth } from 'react-oidc-context';

import { clearStorage } from 'src/communication/apollo/clearStorage';
import { jwtExpirationMessage } from 'src/communication/apollo/link';
import { resetAllStore } from 'src/controller/store/resetAllStore';

export const useOnLogout = () => {
  const auth = useAuth();

  const isValidError = (error: any) =>
    (axios.isAxiosError(error) && error.response?.status === 401) ||
    (error?.graphQLErrors &&
      error.graphQLErrors.length > 0 &&
      error.graphQLErrors[0].message === jwtExpirationMessage) ||
    (error?.networkError && error.networkError.statusCode === 401);

  const shouldLogout = async (error: unknown) => {
    if (!isValidError(error)) return;

    await doLogout();
  };

  const doLogout = async () => {
    clearStorage();
    resetAllStore();
    await auth.signoutRedirect({
      post_logout_redirect_uri: window.location.origin,
    });
  };

  return {
    shouldLogout,
    doLogout,
  };
};
