export enum Route {
  INVOICES = '/invoices',
  ROOT = '/',
  HOME = '/home',
  TREATMENT = 'treatment/:consultationId',
  REPORT = 'report/:consultationId',
  ANALYSIS = 'analysis/:consultationId',
  FULLSCREEN = 'fullscreen/:consultationId',
  ANNOTATIONS = 'annotations/:consultationId',
  CLINIC = 'clinic/:consultationId',
  TREATMENT_VALIDATE = 'treatment/:consultationId/validate',
  STATISTICS = '/statistics',
  PATIENTS = '/patients',
  ACCOUNT = '/account',
  ABOUT = '/about',
  PARTNER = '/partner/:partnerName/patient/:patientId',
  LOCATIONS = '/locations',
  PAYMENT_FAILED = '/paymentFailed',
  PAYMENT_SUCCESS = '/paymentSuccess',
  ID = ':id',
  PATIENT = 'patient/:patientId',
  EDUCATIONAL_SHEETS = '/educational-sheets',
  PREFERENCES = '/preferences',
  PMS_PATIENT = '/pms-patient/:patientPartnerId',
  ACQUISITION = '/acquisition/:patientId',
  XRAY = '/xray/:xrayId',
}
