import { GuestLayout, Loader } from '@allisone/react-components';
import * as Sentry from '@sentry/react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Outlet, useLocation } from 'react-router';

import { SelectLanguage } from '../components/SelectLanguage';
import { NewUserInformationForm } from '../pages/NewUserInformation/components/NewUserInformationForm';
import { ErrorComponent } from '../pages/NotFound/ErrorComponent';
import { Layout } from './layouts';
import { ExpandedUser } from 'src/common/types/user/ExpandedUser';
import { verifyUser } from 'src/communication/api/verifyUser';
import { useConfig } from 'src/controller/context/config/useConfigContext';
import { convertToKeycloakLocale } from 'src/controller/Keycloak/keycloak';
import { useAppStore } from 'src/controller/store';
import { getLocaleFromString } from 'src/controller/utils/app/getLocaleFromString';

export const AuthRoute: FC = () => {
  const auth = useAuth();
  const { isLoading: isConfigLoading } = useConfig();

  const { t } = useTranslation();

  const [user, setUser] = useState<ExpandedUser | null>(null);
  const [userLoading, setUserLoading] = useState(false);

  const [isUserWithNoLocation, setIsUserWithNoLocation] = useState(false);

  const { fetchUser } = useAppStore();

  Sentry.setUser({ id: user?.id, email: user?.email });

  const [shouldNavigateToNewUserInformation, setShouldNavigateToNewUserInformation] =
    useState<boolean>();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [authIsLoaded, setAuthIsLoaded] = useState(false);

  const keyCloakLocale = convertToKeycloakLocale(
    getLocaleFromString(localStorage.getItem('locale') || navigator.language)
  );

  const route = useLocation();

  const query = new URLSearchParams(route.search);
  const idp = query.get('idp') || (auth.user?.profile?.identity_provider as string);

  const fetchUserFromApi = async () => {
    if (auth.isAuthenticated) {
      setUserLoading(true);
      const user = await verifyUser();

      if (
        (auth.user?.profile?.['https://hasura.io/jwt/claims'] as any)?.['x-hasura-user-id'] !==
          user?.id &&
        !hasTriedSignin
      ) {
        // The token doesn't contain a user id, it means it's a user from a third party IdP that first logs in.
        // In that case, we need to wait for it to be synced back to Keycloak, before requesting a new token.
        // This way, the new token will contain the user id.
        // TODO: Sync user to Keycloak in the API Verify User endpoint so that this is synchronous and we don't need to wait here.
        await new Promise((resolve) => setTimeout(resolve, 2000));
        auth.signinRedirect({
          ui_locales: keyCloakLocale,

          ...(idp
            ? {
                extraQueryParams: {
                  kc_idp_hint: idp,
                },
              }
            : {}),
        });
        setHasTriedSignin(true);
      } else {
        fetchUser(user?.id.toString());
        setUser(user);
        setUserLoading(false);
      }
      setShouldNavigateToNewUserInformation(!user?.cguApprovedAt);
    }
  };

  useEffect(() => {
    fetchUserFromApi();
  }, [auth.isAuthenticated]);

  useEffect(() => {
    //We wait that auth.isLoading has been set to true at least once before redirecting to login page
    if (auth.isLoading || (route.pathname === '/' && route.search === '?legacy'))
      setAuthIsLoaded(true);
  }, [auth, route]);

  useEffect(() => {
    if (auth.error && hasAuthParams() && !auth.isLoading && !hasTriedSignin) {
      // Error case when user bookmarked a url with old state in query params
      // We remove the query params and reload the page.
      // Cf #86bywtyuj
      window.history.replaceState({}, document.title, window.location.pathname);
      window.location.reload();
    } else if (
      // Redirect to Keycloak case
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin &&
      authIsLoaded
    ) {
      console.log('User is not authenticated, redirecting to login page');
      auth.signinRedirect({
        ui_locales: keyCloakLocale,

        ...(idp
          ? {
              extraQueryParams: {
                kc_idp_hint: idp,
              },
            }
          : {}),
      });
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin, user, authIsLoaded]);

  useEffect(() => {
    if (
      !userLoading &&
      user?.locations?.filter(({ isEnabled }) => isEnabled).length === 0 &&
      auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !isUserWithNoLocation
    ) {
      setIsUserWithNoLocation(true);
    }
  }, [userLoading, user?.locations?.length]);

  // hook to handle expired session and redirect to login page
  // (silent renew error is triggered when the session is expired and get token fails)
  useEffect(() => {
    return auth.events.addSilentRenewError(() => {
      if (!hasAuthParams() && !auth.activeNavigator) {
        auth.signoutRedirect({
          post_logout_redirect_uri: window.location.origin,
        });
      }
    });
  }, [auth.events, auth.signinSilent]);

  if (
    auth.isLoading ||
    shouldNavigateToNewUserInformation === undefined ||
    isConfigLoading ||
    userLoading
  ) {
    return <Loader screenCenter />;
  }
  if (!auth.isAuthenticated) {
    return <ErrorComponent />;
  } else {
    if (shouldNavigateToNewUserInformation)
      return (
        <GuestLayout HeaderContent={<SelectLanguage />}>
          <NewUserInformationForm onSubmit={() => fetchUserFromApi()} />
        </GuestLayout>
      );

    if (isUserWithNoLocation) {
      return (
        <ErrorComponent message={t('errorNoLocations')} withHomeButton={false} withLogoutButton />
      );
    }

    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
};
