import { Button, ErrorIcon } from '@allisone/react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useOnLogout } from 'src/controller/hooks/useOnLogout';

import styles from './NotFound.module.scss';

type Props = {
  message?: string;
  withHomeButton?: boolean;
  withLogoutButton?: boolean;
};

export const ErrorComponent = ({
  message,
  withHomeButton = true,
  withLogoutButton = false,
}: Props) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();

  const { doLogout } = useOnLogout();

  const handleHomeNavigation = () => {
    navigateTo('/');
    // force refresh the page even if we are already on root
    navigateTo(0);
  };

  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorContent}>
        <ErrorIcon width="108" height="204" className={styles.iconAndButton} />
        <span style={{ margin: 'auto' }}>{message || t('errorNotFound')}</span>
        {withHomeButton && (
          <Button className={styles.iconAndButton} variant="primary" onClick={handleHomeNavigation}>
            {t('home')}
          </Button>
        )}
        {withLogoutButton && (
          <Button className={styles.iconAndButton} variant="primary" onClick={doLogout}>
            {t('logout')}
          </Button>
        )}
      </div>
    </div>
  );
};
