import { Loader } from '@allisone/react-components';
import * as Sentry from '@sentry/react';
import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { PaymentStatusModal } from '../components/modals/PaymentStatusModal';
import { AuthRoute } from './AuthRoute';
import { GuardAcquisition } from './GuardAcquisition';
import { Layout } from './layouts';
import {
  About,
  Account,
  Acquisition,
  Analysis,
  EducationalSheets,
  Home,
  HomePartner,
  Invoices,
  Location,
  Locations,
  NotFound,
  Patients,
  Stats,
  Treatment,
  TreatmentPage,
  TreatmentPreferences,
  XrayRedirect,
} from './lazyRoutes';
import { ProtectedRoute } from './ProtectedRoute';
import { Route as RouteEnum } from './routes';
import { ToasterManager } from './ToasterManager';
import { Role } from 'src/common/enums';
import { Error } from 'src/ui/pages/Error';
import { ElementsTypesProvider } from 'src/ui/pages/Treatment/context';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const Router: FC = () => (
  <BrowserRouter>
    <ToasterManager>
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => {
          if (error.name === 'ChunkLoadError') {
            resetError();
            return (
              <Layout>
                <Loader />
              </Layout>
            );
          }
          return (
            <Layout>
              <Error error={error} resetError={resetError} />
            </Layout>
          );
        }}
      >
        <SentryRoutes>
          <Route element={<AuthRoute />}>
            <Route
              element={
                <ProtectedRoute
                  allowedRoles={[
                    Role.DENTIST,
                    Role.DIRECTOR,
                    Role.HEAD_OF,
                    Role.ASSISTANT,
                    Role.SECRETARY,
                  ]}
                />
              }
            >
              <Route path={RouteEnum.ROOT} element={<Home />} />
            </Route>
            <Route path={RouteEnum.PATIENTS}>
              <Route index element={<Patients />} />
            </Route>
            <Route path={RouteEnum.STATISTICS} element={<Stats />} />
            <Route
              path={RouteEnum.PARTNER}
              element={
                <ElementsTypesProvider>
                  <HomePartner />
                </ElementsTypesProvider>
              }
            />
            <Route path={RouteEnum.PMS_PATIENT} element={<GuardAcquisition />} />
            <Route path={RouteEnum.ACQUISITION} element={<Acquisition />} />
            <Route path={RouteEnum.PATIENT} element={<TreatmentPage />}>
              <Route path={RouteEnum.TREATMENT} element={<Treatment />} />
              <Route path={RouteEnum.TREATMENT_VALIDATE} element={<Treatment />} />
              <Route path={RouteEnum.REPORT} element={<Treatment />} />
              <Route path={RouteEnum.CLINIC} element={<Treatment />} />
              <Route path={RouteEnum.ANALYSIS} element={<Analysis />} />
              <Route path={RouteEnum.FULLSCREEN} element={<Analysis />} />
              <Route path={RouteEnum.ANNOTATIONS} element={<Analysis />} />
            </Route>
            <Route path={RouteEnum.XRAY} element={<XrayRedirect />} />
            <Route path={RouteEnum.STATISTICS} element={<Stats />} />
            <Route path={RouteEnum.PATIENTS}>
              <Route index element={<Patients />} />
            </Route>
            <Route
              element={
                <ProtectedRoute allowedRoles={[Role.HEAD_OF, Role.DIRECTOR, Role.ACCOUNTANT]} />
              }
            >
              <Route path={RouteEnum.LOCATIONS}>
                <Route index element={<Locations />} />
                <Route path={RouteEnum.ID} element={<Location />} />
              </Route>
            </Route>
            <Route
              path={RouteEnum.PARTNER}
              element={
                <ElementsTypesProvider>
                  <HomePartner />
                </ElementsTypesProvider>
              }
            />
            <Route path={RouteEnum.PREFERENCES} element={<TreatmentPreferences />} />
            <Route element={<ProtectedRoute allowedRoles={[Role.HEAD_OF, Role.DENTIST]} />}>
              <Route path={RouteEnum.EDUCATIONAL_SHEETS} element={<EducationalSheets />} />
            </Route>
            <Route path={RouteEnum.INVOICES} element={<Invoices />} />
            <Route path={RouteEnum.HOME} element={<Navigate to={RouteEnum.ROOT} replace />} />
            <Route path={RouteEnum.ACCOUNT} element={<Account />} />
            <Route path={RouteEnum.ABOUT} element={<About />} />
            <Route path={RouteEnum.PAYMENT_FAILED} element={<PaymentStatusModal type="failed" />} />
            <Route
              path={RouteEnum.PAYMENT_SUCCESS}
              element={<PaymentStatusModal type="success" />}
            />
          </Route>

          <Route path="*" element={<NotFound />} />
        </SentryRoutes>
      </Sentry.ErrorBoundary>
    </ToasterManager>
  </BrowserRouter>
);
